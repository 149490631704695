import React, { useState } from "react";

export default function TableRowDisplay(props) {
  const {
    name,
    description,
    quantity,
    price,
    total,
    EditComponent,
    onSave,
    onDelete,
    showDelete,
    percent,
  } = props;

  const [editActive, setEditActive] = useState(false);
  const handleTableRowClick = () => {
    if (!editActive) setEditActive(true);
  };

  const handleSave = (savedItem) => {
    if (onSave) onSave(savedItem);
    setEditActive(false);
  };

  const handleDelete = (item) => {
    if (onDelete) onDelete(item);
    setEditActive(false);
  };

  return (
    <tr
      className="border-b border-gray-200 relative hover:bg-gray-50 hover:cursor-pointer"
      onClick={handleTableRowClick}
      id={props.minerQuoteItem?.id}
    >
      {editActive ? (
        <td colSpan={5} className={"hover:bg-white hover:cursor-auto"}>
          <EditComponent
            {...props}
            onSave={handleSave}
            onDelete={handleDelete}
            showDelete={showDelete}
            onCancel={() => setEditActive(false)}
          />
        </td>
      ) : (
        <>
          <td className="max-w-0 px-0 py-2 align-top">
            <div className="truncate font-medium text-gray-900">
              {name}
              {description && (
                <span className="truncate text-gray-500 ml-4">
                  {description}
                </span>
              )}
            </div>
          </td>
          {quantity && (
            <td className="hidden py-2 pl-8 pr-0 text-right align-top tabular-nums text-gray-700 sm:table-cell">
              {quantity}
            </td>
          )}
          {price && (
            <td className="hidden py-2 pl-8 pr-0 text-right align-top tabular-nums text-gray-700 sm:table-cell">
              {price}
            </td>
          )}
          {percent && (
            <td
              colSpan={2}
              className="hidden py-2 pl-8 pr-0 text-right align-top tabular-nums text-gray-700 sm:table-cell"
            >
              {percent * 100}%
            </td>
          )}
          <td className="py-2 pl-8 pr-0 text-right align-top tabular-nums text-gray-700">
            {total}
          </td>
        </>
      )}
    </tr>
  );
}
