import React, { useState } from "react";
import TableRowDisplay from "./TableRowDisplay";
import { usdFormatter } from "../../../utility/Utility";
import { Button } from "../../../components/Button";
import useFetch from "../../../hooks/useFetch";
import useEditQuoteTaxLine from "../../../hooks/useEditQuoteTaxLine";
import useDeleteQuoteTaxLine from "../../../hooks/useDeleteQuoteTaxLine";
import TaxLineItemEditForm from "./TaxLineItemEditForm";

export default function TaxLineItemsTable({ quote, refetch }) {
  const [newTaxActive, setNewTaxActive] = useState(false);
  const createNewQuoteTaxLine = useFetch("POST", "/quote_tax_lines");
  const editQuoteTaxLine = useEditQuoteTaxLine();
  const deleteQuoteTaxLine = useDeleteQuoteTaxLine();

  const onQuoteTaxLineFormSubmit = async (qtl, createNew = false) => {
    const body = {
      quote_tax_line: {
        ...qtl,
        quote_id: quote.id,
      },
    };
    if (createNew) {
      await createNewQuoteTaxLine(body);
      setNewTaxActive(false);
    } else {
      await editQuoteTaxLine(qtl.id, body);
    }
    if (refetch) refetch();
  };

  const onQuoteTaxLineDelete = async (qtl) => {
    await deleteQuoteTaxLine(qtl);
    if (refetch) refetch();
  };

  return (
    <table className="mt-6 w-full whitespace-nowrap text-left text-sm leading-6">
      <colgroup>
        <col className="w-full" />
        <col />
        <col />
        <col />
      </colgroup>
      <thead className="border-b border-gray-200 text-gray-900">
        <tr>
          <th scope="col" className="px-0 py-3 font-semibold">
            Taxes
          </th>
          <th
            scope="col"
            colSpan={2}
            className="hidden py-3 pl-8 pr-0 text-right font-semibold sm:table-cell"
          >
            Percent
          </th>
          <th scope="col" className="py-3 pl-8 pr-0 text-right font-semibold">
            Total
          </th>
        </tr>
      </thead>
      <tbody>
        {quote.quote_tax_lines?.map((tax_line) => (
          <TableRowDisplay
            key={tax_line.id}
            name={tax_line.name}
            description={tax_line.description}
            percent={tax_line.percent}
            total={usdFormatter.format(tax_line.total)}
            taxLineItem={tax_line}
            EditComponent={TaxLineItemEditForm}
            quote={quote}
            showDelete={true}
            onSave={(qtl) => onQuoteTaxLineFormSubmit(qtl, false)}
            onDelete={(qtl) => onQuoteTaxLineDelete(qtl)}
          />
        ))}
        {newTaxActive && (
          <tr>
            <td colSpan={4}>
              <TaxLineItemEditForm
                quote={quote}
                onSave={(qtl) => onQuoteTaxLineFormSubmit(qtl, true)}
                onCancel={() => setNewTaxActive(false)}
              />
            </td>
          </tr>
        )}
        {!newTaxActive && (
          <tr>
            <td colSpan={3}></td>
            <td
              className={
                "flex justify-items-end items-center pl-4 pt-4 text-right"
              }
            >
              <Button
                variant={"outline"}
                color={"brand"}
                onClick={() => setNewTaxActive(true)}
              >
                Add Tax
              </Button>
            </td>
          </tr>
        )}
      </tbody>
      <tfoot>
        <tr className="text-base font-semibold">
          <th scope="row" className="pt-12 text-gray-700 sm:hidden">
            Total Due
          </th>
          <th
            scope="row"
            colSpan={3}
            className="hidden pt-12 text-right text-gray-700 sm:table-cell"
          >
            Total Due
          </th>
          <td className="pb-0 pl-8 pr-0 pt-12 text-right tabular-nums text-gray-900">
            {usdFormatter.format(quote.total)}
          </td>
        </tr>
      </tfoot>
    </table>
  );
}
