import React, { useState } from "react";
import SearchableDropdown from "../../../components/SearchableDropdown";
import { usdFormatter } from "../../../utility/Utility";
import { Button } from "../../../components/Button";
import { PencilIcon, TrashIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { CheckIcon } from "@heroicons/react/20/solid";
import RowInput from "./RowInput";
import MarketplaceOrderModal from "./MarketplaceOrderModal";
import RowCheckBox from "../../../components/RowCheckBox";
import { MarketplaceAssignmentModal } from "./MarketplaceAssignmentModal";

export default function MinerEditForm({
  minerQuoteItem,
  onCancel,
  onSave,
  onDelete,
  minerConfigurations,
  showDelete,
  showFeeOptions,
  disabled,
}) {
  const [selectedMinerId, setSelectedMinerId] = useState(
    minerQuoteItem?.miner_configuration?.id,
  );
  const [quantity, setQuantity] = useState(minerQuoteItem?.quantity ?? 1);
  const [price, setPrice] = useState(minerQuoteItem?.price ?? 0);
  const [overclocked, setOverclocked] = useState(false);
  const [installFee, setInstallFee] = useState(false);
  const [overclockFee, setOverclockFee] = useState(false);
  const [monthlySlotRent, setMonthlySlotRent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showMarketplaceDialog, setShowMarketplaceDialog] = useState(false);
  const [showMarketplaceAssignmentDialog, setShowMarketplaceAssignmentDialog] =
    useState(false);
  const marketplaceAssetIds = minerQuoteItem?.marketplace_asset_numbers;

  const onComplete = () => {
    setLoading(true);
    const m = {
      ...minerQuoteItem,
      quantity: quantity,
      price: price,
      miner_configuration_id: selectedMinerId,
      overclocked: overclocked,
      installation_fee: installFee ? "1" : "0", // Fun rails backwards compat
      overclocking_fee: overclockFee ? "1" : "0", // Fun rails backwards compat
      monthly_slot_rent: monthlySlotRent ? "1" : "0", // Fun rails backwards compat
    };
    if (onSave) onSave(m);
    setLoading(false);
  };

  return (
    <div className={"flex flex-col justify-center"}>
      <MarketplaceOrderModal
        modalOpen={showMarketplaceDialog}
        setOpen={(value) => setShowMarketplaceDialog(value)}
        onConfirm={(mqi) => {
          setLoading(true);
          if (onSave)
            onSave({
              ...mqi,
              installation_fee: "1",
            });
          setLoading(false);
        }}
      />
      <div className="flex -space-x-px my-6">
        <div className={"min-w-[350px]"}>
          <label htmlFor="location" className="text-gray-500">
            Miner Type
          </label>
          <SearchableDropdown
            disabled={disabled}
            options={minerConfigurations}
            className={"focus:z-10"}
            preSelectedId={selectedMinerId}
            onSelectedId={setSelectedMinerId}
            displayField={"name"}
            showLabel={false}
            idField={"id"}
            secondaryLabelFn={(miner) =>
              `${miner.manufacturer} | ${miner.hash_rate} ${miner.hash_rate_units}`
            }
            filterFn={(option, query) => {
              return (
                option.name.toLowerCase().includes(query.toLowerCase()) ||
                option.hash_rate_units
                  .toLowerCase()
                  .includes(query.toLowerCase()) ||
                option.manufacturer.toLowerCase().includes(query.toLowerCase())
              );
            }}
          />
        </div>
        <RowInput
          disabled={disabled}
          name={"quantity"}
          placeholder={"1"}
          label={"Quantity"}
          type={"number"}
          value={quantity}
          onChange={setQuantity}
        />
        <RowInput
          disabled={disabled}
          name={"price"}
          placeholder={"$2,500"}
          type={"number"}
          label={"Price"}
          value={price}
          onChange={setPrice}
        />
        <RowInput
          name={"total"}
          value={`${usdFormatter.format(quantity * price)}`}
          label={"Total"}
          disabled={true}
        />
      </div>
      {marketplaceAssetIds?.length > 0 && (
        <div className={"text-gray-700 py-4 px-2"}>
          <p>
            This item is linked to a marketplace order and cannot be edited.
          </p>
          <span className={"flex flex-inline items-center"}>
            This would be fulfilled by asset(s):{" "}
            {marketplaceAssetIds.map((asset_num) => {
              return (
                <a
                  className={"font-bold underline px-1"}
                  href={`/assets/shortlinks/hosting?asset_number=${asset_num}`}
                  key={asset_num}
                  target={"_blank"}
                  rel={"noreferrer"}
                >
                  Asset {asset_num}
                </a>
              );
            })}
            <PencilIcon
              onClick={() => setShowMarketplaceAssignmentDialog(true)}
              className="ml-4 h-4 w-4 cursor-pointer text-gray-500 hover:text-gray-800"
            />
          </span>
          <MarketplaceAssignmentModal
            onConfirm={(mqi) => {
              if (onSave) {
                setLoading(true);
                onSave(mqi);
                setShowMarketplaceAssignmentDialog(false);
                setLoading(false);
              }
            }}
            setOpen={(val) => setShowMarketplaceAssignmentDialog(val)}
            modalOpen={showMarketplaceAssignmentDialog}
            minerQuoteItem={minerQuoteItem}
          />
        </div>
      )}
      {showFeeOptions && (
        <div className={"flex flex-row mb-3 justify-end space-x-3"}>
          <RowCheckBox
            name={"overclocked"}
            label={"Overclocked"}
            onValueChanged={(value) => setOverclocked(value)}
          />
          <RowCheckBox
            name={"install_fee"}
            label={"Add Install Fee"}
            onValueChanged={(value) => setInstallFee(value)}
          />
          <RowCheckBox
            name={"monthly_slot_rent"}
            label={"Add Monthly Slot Rent"}
            onValueChanged={(value) => setMonthlySlotRent(value)}
          />
          <RowCheckBox
            name={"overclock_fee"}
            label={"Add Overclock Fee"}
            onValueChanged={(value) => setOverclockFee(value)}
          />
        </div>
      )}
      <div className={"flex flex-row mb-4"}>
        <div className={"flex-1"}>
          {showDelete && (
            <Button
              variant={"outline"}
              color={"red"}
              className={"focus:z-10 py-1"}
              onClick={() => onDelete(minerQuoteItem)}
            >
              <TrashIcon className={"h-5 w-5"} />
            </Button>
          )}
          {showFeeOptions && (
            <Button
              variant={"outline"}
              color={"brand"}
              className={"focus:z-10 py-1"}
              onClick={() => setShowMarketplaceDialog(true)}
            >
              Add From Marketplace
            </Button>
          )}
        </div>
        <div className={"flex flex-row self-end"}>
          {!disabled && (
            <Button
              onClick={onComplete}
              className={"focus:z-10"}
              loading={loading}
            >
              <CheckIcon className={"w-5 h-5"} />
            </Button>
          )}
          <div className={"px-[2px]"}></div>
          <Button
            variant={"outline"}
            color={"slate"}
            onClick={onCancel}
            className={"focus:z-10"}
            loading={loading}
          >
            <XMarkIcon className={"w-5 h-5"} />
          </Button>
        </div>
      </div>
    </div>
  );
}
