import { getCsrfToken } from "../utility/Utility";

export default function useDeleteQuoteTaxLine() {
  return async (quote_tax_line) => {
    const response = await fetch(
      `/quote_tax_lines/${quote_tax_line.id}?format=json`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          authenticity_token: getCsrfToken(),
        }),
      },
    );
    return await response.json();
  };
}
