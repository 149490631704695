import React from "react";

export const RowInput = ({
  label,
  placeholder,
  name,
  value,
  disabled = false,
  onChange,
  type = "text",
  ...otherInputProps
}) => {
  return (
    <div className="w-full min-w-0 flex-1">
      <label htmlFor={name} className="text-gray-500">
        {label}
      </label>
      <input
        type={type}
        name={name}
        onChange={(ev) => onChange(ev.target.value)}
        className="relative overflow-ellipsis block w-full rounded-none rounded-bl-md disabled:text-gray-500 disabled:bg-gray-100 border-0 bg-transparent py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-brand sm:text-sm sm:leading-6"
        placeholder={placeholder}
        disabled={disabled}
        value={value}
        {...otherInputProps}
      />
    </div>
  );
};

export default RowInput;
