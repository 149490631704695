import React, { useState } from "react";
import TableRowDisplay from "./TableRowDisplay";
import { usdFormatter } from "../../../utility/Utility";
import LineItemEditForm from "./LineItemEditForm";
import HostingDepositEditForm from "./HostingDepositEditForm";
import { Button } from "../../../components/Button";
import useFetch from "../../../hooks/useFetch";
import useEditQuoteLine from "../../../hooks/useEditQuoteLine";
import useDeleteQuoteLine from "../../../hooks/useDeleteQuoteLine";

export default function LineItemsTable({ quote, refetch }) {
  const [newFeeActive, setNewFeeActive] = useState(false);
  const createNewQuoteLine = useFetch("POST", "/quote_lines");
  const editQuoteLine = useEditQuoteLine();
  const deleteQuoteLine = useDeleteQuoteLine();

  const onQuoteLineFormSubmit = async (ql, createNew = false) => {
    const body = {
      quote_line: {
        ...ql,
        quote_id: quote.id,
      },
    };
    if (createNew) {
      await createNewQuoteLine(body);
      setNewFeeActive(false);
    } else {
      await editQuoteLine(ql.id, body);
    }
    if (refetch) refetch();
  };

  const onQuoteLineDelete = async (ql) => {
    await deleteQuoteLine(ql);
    if (refetch) refetch();
  };

  return (
    <table className="mt-16 w-full whitespace-nowrap text-left text-sm leading-6">
      <colgroup>
        <col className="w-full" />
        <col />
        <col />
        <col />
      </colgroup>
      <thead className="border-b border-gray-200 text-gray-900">
        <tr>
          <th scope="col" className="px-0 py-3 font-semibold">
            Fees
          </th>
          <th
            scope="col"
            className="hidden py-3 pl-8 pr-0 text-right font-semibold sm:table-cell"
          >
            Quantity
          </th>
          <th
            scope="col"
            className="hidden py-3 pl-8 pr-0 text-right font-semibold sm:table-cell"
          >
            Price
          </th>
          <th scope="col" className="py-3 pl-8 pr-0 text-right font-semibold">
            Total
          </th>
        </tr>
      </thead>
      <tbody>
        {quote.quote_lines
          ?.filter((ql) => ql.quantity > 0)
          .map((ql) => (
            <TableRowDisplay
              key={ql.id}
              name={ql.name}
              description={ql.description}
              quantity={ql.quantity}
              price={usdFormatter.format(ql.cost)}
              total={usdFormatter.format(ql.quantity * ql.cost)}
              lineItem={ql}
              EditComponent={LineItemEditForm}
              showDelete={true}
              onSave={(ql) => onQuoteLineFormSubmit(ql, false)}
              onDelete={(ql) => onQuoteLineDelete(ql)}
            />
          ))}
        <TableRowDisplay
          name={"Hosting Deposit"}
          quantity={quote.waive_first_month_hosting ? "1" : "2"}
          price={usdFormatter.format(quote.single_month_deposit)}
          total={usdFormatter.format(quote.deposit)}
          quote={quote}
          EditComponent={HostingDepositEditForm}
        />
        {!newFeeActive && (
          <tr>
            <td colSpan={3}></td>
            <td
              className={
                "flex justify-items-end items-center pl-4 pt-4 text-right"
              }
            >
              <Button
                variant={"outline"}
                color={"brand"}
                onClick={() => setNewFeeActive(true)}
              >
                Add Fee
              </Button>
            </td>
          </tr>
        )}
        {newFeeActive && (
          <tr>
            <td colSpan={4}>
              <LineItemEditForm
                onSave={(mqi) => onQuoteLineFormSubmit(mqi, true)}
                onCancel={() => setNewFeeActive(false)}
              />
            </td>
          </tr>
        )}
      </tbody>
      <tfoot>
        <tr className={"text-base font-normal"}>
          <th scope="row" className="px-0 pb-0 pt-12 text-gray-700 sm:hidden">
            Subtotal
          </th>
          <th
            scope="row"
            colSpan={3}
            className="hidden px-0 pb-0 pt-12 text-right text-gray-700 sm:table-cell"
          >
            Subtotal
          </th>
          <td className="pb-0 pl-8 pr-0 pt-12 text-right tabular-nums text-gray-900">
            {usdFormatter.format(quote.subtotal)}
          </td>
        </tr>
      </tfoot>
    </table>
  );
}
