import React, { useState } from "react";
import { usdFormatter } from "../../../utility/Utility";
import { Button } from "../../../components/Button";
import { TrashIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { CheckIcon } from "@heroicons/react/20/solid";
import RowInput from "./RowInput";
import SearchableDropdown from "../../../components/SearchableDropdown";
import Dropdown from "../../../components/Dropdown";

export default function LineItemEditForm({
  lineItem,
  onCancel,
  onSave,
  onDelete,
  showDelete,
}) {
  const [name, setName] = useState(lineItem?.name ?? "");
  const [quantity, setQuantity] = useState(lineItem?.quantity ?? 1);
  const [cost, setCost] = useState(lineItem?.cost ?? 0);
  const [loading, setLoading] = useState(false);

  const feeOptions = [
    {
      name: "Installation Fee",
    },
    {
      name: "Monthly Slot Rent",
    },
    {
      name: "Demand Surcharge",
    },
    {
      name: "Website Discount",
    },
    {
      name: "Overclocking Fee",
    },
    {
      name: "Tariffs",
    },
  ];

  const onComplete = () => {
    setLoading(true);
    const item = {
      ...lineItem,
      quantity: quantity,
      cost: cost,
      name: name,
    };
    if (onSave) onSave(item);
    setLoading(false);
  };

  return (
    <div className={"flex flex-col justify-center"}>
      <div className="flex -space-x-px my-6">
        <div className={"min-w-[300px]"}>
          <label className="text-gray-500">Select name</label>
          <Dropdown
            fullWidth={true}
            includeBlank={true}
            style={"smarthost"}
            defaultVal={{ title: name, value: name }}
            items={feeOptions.map((fee) => ({
              title: fee.name,
              value: fee.name,
            }))}
            onSelectionChange={(selected) => setName(selected.value)}
          />
        </div>
        <RowInput
          name={"quantity"}
          placeholder={"1"}
          label={"Quantity"}
          type={"number"}
          value={quantity}
          onChange={setQuantity}
        />
        <RowInput
          name={"cost"}
          placeholder={"$250"}
          type={"number"}
          label={"Cost"}
          value={cost}
          onChange={setCost}
        />
        <RowInput
          name={"total"}
          value={`${usdFormatter.format(quantity * cost)}`}
          label={"Total"}
          disabled={true}
        />
      </div>
      <div className={"flex flex-row mb-4"}>
        <div className={"flex-1"}>
          {showDelete && (
            <Button
              variant={"outline"}
              color={"red"}
              className={"focus:z-10 py-1"}
              onClick={() => onDelete(lineItem)}
            >
              <TrashIcon className={"h-5 w-5"} />
            </Button>
          )}
        </div>
        <div className={"flex flex-row self-end"}>
          <Button
            onClick={onComplete}
            className={"focus:z-10"}
            loading={loading}
          >
            <CheckIcon className={"w-5 h-5"} />
          </Button>
          <div className={"px-[2px]"}></div>
          <Button
            variant={"outline"}
            color={"slate"}
            onClick={onCancel}
            className={"focus:z-10"}
            loading={loading}
          >
            <XMarkIcon className={"w-5 h-5"} />
          </Button>
        </div>
      </div>
    </div>
  );
}
