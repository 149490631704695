import React, { useState } from "react";
import { usdFormatter } from "../../../utility/Utility";
import { Button } from "../../../components/Button";
import { TrashIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { CheckIcon } from "@heroicons/react/20/solid";
import RowInput from "./RowInput";
import Dropdown from "../../../components/Dropdown";

export default function TaxLineItemEditForm({
  quote,
  taxLineItem,
  onCancel,
  onSave,
  onDelete,
  showDelete,
}) {
  const [name, setName] = useState(taxLineItem?.name ?? "");
  const [percent, setPercent] = useState(taxLineItem?.percent);
  const [loading, setLoading] = useState(false);

  const feeOptions = [
    {
      name: "Tariffs",
    },
    {
      name: "Tax",
    },
    {
      name: "Payment Processing Fee",
    },
  ];

  const onComplete = () => {
    setLoading(true);
    const item = {
      ...taxLineItem,
      percent: percent,
      name: name,
    };
    if (onSave) onSave(item);
    setLoading(false);
  };

  return (
    <div className={"flex flex-col justify-center"}>
      <div className="flex -space-x-px my-6">
        <div className={"min-w-[300px]"}>
          <label className="text-gray-500">Select name</label>
          <Dropdown
            fullWidth={true}
            includeBlank={true}
            style={"smarthost"}
            defaultVal={{ title: name, value: name }}
            items={feeOptions.map((fee) => ({
              title: fee.name,
              value: fee.name,
            }))}
            onSelectionChange={(selected) => setName(selected.value)}
          />
        </div>
        <RowInput
          name={"percent"}
          placeholder={1.5}
          type={"number"}
          label={"Percent"}
          value={percent * 100}
          onChange={(value) => setPercent(value / 100)}
          step={"any"}
          min={0}
          max={100}
        />
        <RowInput
          name={"total"}
          value={`${
            taxLineItem ? usdFormatter.format(percent * quote?.subtotal) : 0
          }`}
          label={"Total"}
          disabled={true}
        />
      </div>
      <div className={"flex flex-row mb-4"}>
        <div className={"flex-1"}>
          {showDelete && (
            <Button
              variant={"outline"}
              color={"red"}
              className={"focus:z-10 py-1"}
              onClick={() => onDelete(taxLineItem)}
            >
              <TrashIcon className={"h-5 w-5"} />
            </Button>
          )}
        </div>
        <div className={"flex flex-row self-end"}>
          <Button
            onClick={onComplete}
            className={"focus:z-10"}
            loading={loading}
          >
            <CheckIcon className={"w-5 h-5"} />
          </Button>
          <div className={"px-[2px]"}></div>
          <Button
            variant={"outline"}
            color={"slate"}
            onClick={onCancel}
            className={"focus:z-10"}
            loading={loading}
          >
            <XMarkIcon className={"w-5 h-5"} />
          </Button>
        </div>
      </div>
    </div>
  );
}
